import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxt) => {
  nuxt.vueApp.use(Vue3Toasity, {
    position: "bottom-center",
    duration: 5000,
    containerOptions: {
      className: "toastify-container",
    },
  } as ToastContainerOptions);
});
