import { Exam, exams } from '../content'

const currentExam = ref<Exam>(Exam.ENEM)
const currentCompetenceNumber = ref(0)
const isOpen = ref(false)

export const useCompetenceModal = () => {
  const exam = computed(() => {
    return exams[currentExam.value]
  })

  const competence = computed(() => {
    return exam.value.competences[currentCompetenceNumber.value]
  })

  const openCompetenceModal = (exam: Exam, competenceNumber: number) => {
    currentExam.value = exam
    currentCompetenceNumber.value = competenceNumber
    isOpen.value = true
  }

  const closeCompetenceModal = () => {
    isOpen.value = false
  }

  return {
    isOpen,
    competence,
    openCompetenceModal,
    closeCompetenceModal,
  }
}
