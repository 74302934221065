/**
 * @name pwa-refresh.ts
 * @description PWA Auto Refresh Plugin
 * @path plugins/pwa-refresh.ts 
 * @docs https://vite-pwa-org.netlify.app/frameworks/nuxt
 */
export default defineNuxtPlugin(() => {
  const { $pwa } = useNuxtApp()

  watch(() => $pwa?.needRefresh, (value) => {
    if (value) {
      $pwa?.updateServiceWorker()
    }
  })
})