import type { UIConfirmProps } from "../components/confirm.vue";

const confirmProps = ref<UIConfirmProps>({
  title: "Are you sure?",
  content: "This action cannot be undone.",
  type: "action",
  onConfirm: () => {},
  onCancel: () => {},
});
const confirmModel = ref<boolean>(false);
const isConfirming = ref<boolean>(false);

export const useConfirm = () => {
  const askForConfirmation = (params: UIConfirmProps) => {
    confirmProps.value = { ...confirmProps.value, ...params };
    confirmModel.value = true;
  };

  const close = () => {
    confirmModel.value = false;

    if (confirmProps.value.onCancel) {
      confirmProps.value.onCancel();
    }
  };

  const confirm = async () => {
    if (confirmProps.value.onConfirm) {
      isConfirming.value = true;
      if (confirmProps.value.inlineLoading) {
        await confirmProps.value.onConfirm();
      } else {
        confirmProps.value.onConfirm();
      }

      close();
      isConfirming.value = false;
    }
  };

  return {
    confirmModel,
    confirmProps,
    isConfirming,
    close,
    confirm,
    askForConfirmation,
  };
};
