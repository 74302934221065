<template>
  <VDialog
    v-model="model"
    max-width="450"
    :persistent="isConfirming"
    @keydown.enter="confirm"
    @keydown.esc="close"
  >
    <VCard
      rounded="xl"
      elevation="0"
      class="rounded-2xl overflow-hidden bg-white"
    >
      <!-- Header -->
      <div
        class="flex pl-4 pr-2 py-4 items-center text-white"
        :class="[color]"
      >
        <h2 class="text-2xl font-semibold text-white">
          {{ title }}
        </h2>

        <VBtn
          rounded="full"
          class="ml-auto"
          variant="text"
          @click="close"
        >
          <VIcon
            size="28"
            icon="mdi-close"
          />
        </VBtn>
      </div>

      <!-- Content -->
      <div class="p-4 py-6">
        {{ content }}
      </div>

      <!-- Actions -->
      <div class="bg-gray-100 flex justify-end px-4 py-2 gap-2">
        <VBtn
          variant="text"
          rounded="full"
          :disabled="isConfirming"
          @click="close"
        >
          {{ cancelText }}
        </VBtn>
        <VBtn
          :class="[color]"
          rounded="full"
          dark
          flat
          :loading="isConfirming"
          @click="confirm"
        >
          <span class="text-white px-2">{{ confirmText }}</span>
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script lang="ts" setup>
import { useConfirm } from '../../../domains/global/composables/useConfirm'

export interface UIConfirmProps {
  title: string
  content: string
  type?: 'action' | 'destructive'
  confirmText?: string
  cancelText?: string
  inlineLoading?: boolean
  onConfirm: () => void | Promise<void>
  onCancel: () => void
}

const props = withDefaults(defineProps<UIConfirmProps>(), {
  inlineLoading: false,
  type: 'action',
  confirmText: 'Confirmar',
  cancelText: 'Cancelar',
})

const model = defineModel<boolean>('open')

const color = computed(() => {
  return props.type === 'action' ? 'bg-green-600' : 'bg-red-600'
})

const { isConfirming, confirm, close } = useConfirm()
</script>
