<template>
  <VDialog
    v-model="isOpen"
    max-width="800"
    :fullscreen="display.smAndDown.value"
    class="md:overflow-hidden"
    z-index="5000"
  >
    <VCard class="md:!rounded-[40px]">
      <div class="relative md:overflow-hidden">
        <div class="md:h-[calc(100vh-48px)] overflow-auto">
          <div v-if="article">
            <!-- Header -->
            <div class="relative">
              <VImg
                v-if="featureImage"
                cover
                :height="display.smAndDown.value ? 200 : 300"
                :src="featureImage"
              />
            </div>

            <!-- Content -->
            <div class="p-4 max-w-[650px] mx-auto">
              <!-- Header -->
              <div v-if="article.title">
                <span class="text-gray-500">Conteúdo</span>
                <h1 class="text-3xl mt-2">
                  {{ article.title }}
                </h1>
              </div>

              <!-- Body -->
              <ContentBody v-if="!isPending && html" class="mt-6" :html />
            </div>
          </div>

          <div class="flex">
            <UILoading :open="isPending" />
          </div>
        </div>

        <!-- Close button -->
        <VBtn
          icon="mdi-close"
          size="small"
          class="shadow-xl fixed md:absolute top-4 right-4 border-[1px] !border-black/50"
          @click="closeContent"
        />

        <!-- Back button -->
        <VBtn
          v-if="isBackAvailable"
          icon="mdi-chevron-left"
          size="small"
          class="shadow-xl fixed md:absolute top-4 left-4 border-[1px] !border-black/50"
          @click="back"
        />

        <!-- Theme button -->
        <VBtn
          v-if="isThemeContent"
          color="primary"
          rounded
          size="large"
          class="fixed md:absolute bottom-6 left-1/2 -translate-x-1/2"
          @click="selectTheme"
        >
          <template #prepend>
            <img src="/images/icons/write.svg" class="w-6 h-6" />
          </template>
          <span class="pr-2">Escolher tema</span>
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script lang="ts" setup>
import { ContentBody } from ".";
import { UILoading } from "~/domains/global/components";
import { useContentViewer } from "~/domains/content/composables/useContentViewer";
import { useDisplay } from "vuetify";

const router = useRouter();
const route = useRoute();
const display = useDisplay();

const {
  isOpen,
  isPending,
  article,
  html,
  isBackAvailable,
  isThemeContent,
  currentRef,
  back,
  closeContent,
} = useContentViewer();

const featureImage = computed(() => {
  if (!article.value?.featureImage) return "";

  return (
    article.value?.featureImage.replace(
      "conteudo.coredacao.com",
      "coredacao.imgix.net"
    ) + "?w=800&h=300&fit=crop&crop=entropy&auto=format,enhance&q=75"
  );
});

const selectTheme = () => {
  const slug = article.value?.slug;

  if (!slug) return;

  router.push({
    ...route,
    query: {
      ...route.query,
      tema: article.value?.slug,
    },
  });

  closeContent();
};
</script>
