import { enemData } from './enem'

export enum Exam {
  ENEM = 'ENEM',
}

export const exams = {
  [Exam.ENEM]: {
    type: Exam.ENEM,
    ...enemData,
  },
}
