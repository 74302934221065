import { useQueryClient } from '@tanstack/vue-query'
import { toast } from 'vue3-toastify'
import { contentQueries } from '..'
import type { Article, ArticleOverview } from '../types'

const isOpen = ref(false)
const isPending = ref(false)
const data = ref<Article | null>(null)
const isThemeContent = ref(false)
const currentSlug = ref('')
const currentArticle = ref<ArticleOverview | null>(null)
const currentRef = ref<Ref<Element> | undefined>(undefined)
const history = ref<Article[]>([])

export const useContentViewer = () => {
  const queryClient = useQueryClient()

  // Clear history on isOpen = false
  watch(isOpen, (val) => {
    if (!val) {
      history.value = []
    }
  })

  const fetchContent = async () => {
    isPending.value = true
    data.value = null
    try {
      const res = await queryClient.fetchQuery({
        ...contentQueries.contentBySlugQuery(currentSlug.value),
      })

      data.value = res
    }
    catch (e) {
      toast.error('Artigo não encontrado')

      if (isBackAvailable.value) {
        back()
      }
      else {
        closeContent()
      }
    }

    isPending.value = false
  }

  const openContent = (art: ArticleOverview, isTheme?: boolean, ref?: Element) => {
    currentRef.value = ref ?? undefined

    if  (!art) {
      return
    }

    // Handle history
    if (isOpen.value && data.value) {
      history.value.push(data.value)
    }

    currentSlug.value = art.slug
    currentArticle.value = art
    isThemeContent.value = !!isTheme
    isOpen.value = true

    fetchContent()
  }

  const openContentBySlug = (slug: string) => {
    // Handle history
    if (isOpen.value && data.value) {
      history.value.push(data.value)
    }

    currentSlug.value = slug
    currentArticle.value = null
    isOpen.value = true
    isThemeContent.value = false

    fetchContent()
  }

  const closeContent = () => {
    isOpen.value = false
  }

  const back = () => {
    const prev = history.value.pop()

    if (prev) {
      currentArticle.value = prev
      currentSlug.value = prev.slug
      fetchContent()
    }
  }

  const isBackAvailable = computed(() => {
    return history.value.length > 0
  })

  const html = computed(() => {
    return data.value?.html
  })

  const article = computed(() => {
    return {
      ...currentArticle.value,
      ...data.value,
    }
  })

  return {
    isOpen,
    isPending,
    article,
    html,
    isBackAvailable,
    isThemeContent,
    currentSlug,
    currentRef,
    openContent,
    openContentBySlug,
    closeContent,
    back,
  }
}
