<template>
  <VDialog
    v-model="isOpen"
    max-width="600"
  >
    <div class="bg-white rounded-xl overflow-hidden">
      <!-- Header -->
      <div
        class="flex pl-8 pr-2 py-4 items-center text-white"
        :style="{
          backgroundColor: competence.color,
        }"
      >
        <h2 class="text-2xl text-white">
          {{ competence.text }}
        </h2>

        <VBtn
          rounded="full"
          class="ml-auto"
          variant="text"
          @click="closeCompetenceModal"
        >
          <VIcon
            size="28"
            icon="mdi-close"
          />
        </VBtn>
      </div>

      <!-- Content -->
      <div class="p-4">
        <!-- Tabs -->
        <VTabs
          v-model="tab"
          class="-mt-3 mb-4"
        >
          <VTab value="description">
            Descrição
          </VTab>
          <VTab value="levels">
            Níveis
          </VTab>
        </VTabs>

        <!-- Tabs content -->
        <VTabsWindow
          v-model="tab"
          class=" max-h-[60dvh] overflow-auto"
        >
          <VTabsWindowItem value="description">
            <p>
              {{ competence.description }}
            </p>
          </VTabsWindowItem>

          <VTabsWindowItem value="levels">
            <div class="grid gap-2">
              <div
                v-for="level in competence.levels"
                :key="level.grade"
              >
                <h3>
                  {{ level.grade }} pontos
                </h3>
                <p>
                  {{ level.text }}
                </p>
              </div>
            </div>
          </VTabsWindowItem>
        </VTabsWindow>
      </div>
    </div>
  </VDialog>
</template>

<script lang="ts" setup>
import { useCompetenceModal } from '~/domains/exam/composables/useCompetenceModal'

const { isOpen, competence, closeCompetenceModal } = useCompetenceModal()

watch(isOpen, () => {
  if (isOpen.value) {
    tab.value = 'description'
  }
})

const tab = ref('description')
</script>
