import { queryOptions } from '@tanstack/vue-query'
import type { Article } from '../types'
import { useApi } from '~/domains/global/composables/useApi'

export const contentBySlugQuery = (slug: MaybeRef<string | null>) => {
  return queryOptions({
    queryKey: ['articleBySlug', toValue(slug)],
    queryFn: async () => {
      const api = useApi()
      const data = await api<Article>('/content/articleBySlug/' + toValue(slug), {
        method: 'GET',
      })

      return data
    },
    staleTime: 1000 * 60 * 60,
  })
}
