<template>
  <VFadeTransition leave-absolute>
    <div
      v-if="open"
      class="inline-block p-2 mx-auto rounded-full bg-white shadow border"
    >
      <VProgressCircular
        indeterminate
        color="primaryAlt"
      />
    </div>
  </VFadeTransition>
</template>

<script lang="ts" setup>
const open = defineModel<boolean>('open')
</script>
