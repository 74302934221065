import { queryOptions } from '@tanstack/vue-query'
import type { ArticleOverview } from '../types'
import { useApi } from '~/domains/global/composables/useApi'

export const contentByTagQuery = (tag: string) => {
  return queryOptions({
    queryKey: ['contentByTag', tag],
    queryFn: async () => {
      const api = useApi()
      const data = await api<ArticleOverview[]>('/content/articlesByTag/' + tag, {
        method: 'GET',
      })

      // Return data without last element
      return data.slice(0, -1)
    },
    staleTime: 1000 * 60 * 60,
  })
}
