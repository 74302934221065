import { useToken } from '~/domains/auth/composables/useToken'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const token = useToken()
  const $api = $fetch.create({
    baseURL: config.public.apiURL,
    onRequest({ options }) {
      if (token.value) {
        options.headers = new Headers({
          ...options.headers,
          Authorization: `${token.value}`,
        })
      }
    },
  })

  return {
    provide: {
      api: $api,
    },
  }
})
