export const ThemeColors = {
  primary: '#FF6338',
  primaryAlt: '#41D69D',
  accent: '#263238',
  emphasis: '#17173d',
  background: '#FFF9F5',
  secondary: '#333',
  bg: '#FEF6F0',
  info: '#26A69A',
  warning: '#FFC107',
  error: '#DD2C00',
  success: '#00E676',
}
